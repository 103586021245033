import type { UseSeoMetaInput } from '@unhead/vue'
import type { Page } from '#payload/types'
import { GetPageDocument } from '@/graphql/payload'

interface PageQueryResult {
  Pages: {
    docs: Page[]
  }
}

export const usePayloadPage = async (slug: string) => {
  const nuxtApp = useNuxtApp()
  const config = useRuntimeConfig()
  const globalsStore = useGlobalsStore()

  const doc = ref<Page | null>(null)

  const { data } = await useAsyncQuery<PageQueryResult>({
    query: GetPageDocument,
    variables: { slug },
    clientId: 'payload',
  })

  doc.value = data.value?.Pages.docs[0] || null

  if (doc.value) {
    const docMeta = doc.value.meta
    const seoMeta: UseSeoMetaInput = { title: doc.value.title }

    seoMeta.title = docMeta?.title || seoMeta.title

    seoMeta.ogTitle = seoMeta.twitterTitle = `${seoMeta.title} | ${
      globalsStore.site?.meta?.title || config.public.siteName
    }`

    seoMeta.description =
      seoMeta.ogDescription =
      seoMeta.twitterDescription =
        docMeta?.description || ''

    if (docMeta?.image) {
      const ogImage = useRelationshipField(docMeta?.image)

      seoMeta.ogImage = ogImage.value?.sizes?.opengraph?.url || ''
    }

    nuxtApp.runWithContext(() => useSeoMeta(seoMeta))
  } else {
    throw createError({
      statusCode: 404,
      message: 'Page data missing.',
    })
  }

  return doc
}
